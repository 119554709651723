export function printFunction (dataView, patient, profile, imbB64, nameDoc) {
  return new Promise((resolve) => {
    let subtoTal = 0
    for (let i = 0; i < dataView.processArr.length; i++) {
      subtoTal = subtoTal + (parseInt(dataView.processArr[i].processCost))
    }
    let dctoVal = 0
    if (dataView.descuento) {
      dctoVal = dataView.descuento
    }
    let totalView = ''
    if (dataView.dsctoType === '%') {
      totalView = (parseFloat(subtoTal) - parseFloat(subtoTal) * parseFloat(dctoVal).toFixed(2) / 100).toFixed(2)
    } else {
      totalView = (parseFloat(subtoTal) - parseFloat(dctoVal)).toFixed(2)
    }
    var pdfMake = require('pdfmake/build/pdfmake.js')
    if (pdfMake.vfs === undefined) {
      var pdfFonts = require('pdfmake/build/vfs_fonts.js')
      pdfMake.vfs = pdfFonts.pdfMake.vfs
    }
    const productTable = []
    productTable.push(
      [
        {
          text: 'Cantidad',
          fontSize: 10,
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Piezas',
          fontSize: 10,
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Producto - Servicio',
          fontSize: 10,
          fillColor: '#eaf2f5',
          border: [false, true, false, true],
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Valor Unitario',
          fontSize: 10,
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
        {
          text: 'Total',
          fontSize: 10,
          border: [false, true, false, true],
          alignment: 'right',
          fillColor: '#eaf2f5',
          margin: [0, 5, 0, 5],
          textTransform: 'uppercase',
        },
      ],
    )
    for (let i = 0; i < dataView.processArr.length; i++) {
      let pieces = ''
      if (dataView.processArr[i].isGeneral === true) {
        pieces = 'General'
      } else {
        for (let j = 0; j < dataView.processArr[i].teethIdArray.length; j++) {
          if (j === 0) {
            pieces = dataView.processArr[i].teethIdArray[j].pieza
          } else {
            pieces = pieces + ', ' + dataView.processArr[i].teethIdArray[j].pieza
          }
        }
      }
      const productArr = [
        {
          text: '1',
          fontSize: 10,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: pieces,
          fontSize: 10,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: dataView.processArr[i].processLabel,
          fontSize: 10,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          border: [false, false, false, true],
          fontSize: 10,
          text: '$' + dataView.processArr[i].processCost,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 5, 0, 5],
        },
        {
          border: [false, false, false, true],
          fontSize: 10,
          text: '$' + dataView.processArr[i].processCost,
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 5, 0, 5],
        },
      ]
      productTable.push(productArr)
    }
    const pdfFormat = {
      footer:
      {
        text: 'Generado por: J2RTECH Comunicarse a los siguientes contactos: \n Teléfono: 0988511884 - Correo: j2rtechsas@gmail.com \n Dirección: Yanahurco 4-91 y Río Upano',
        style: 'notesText',
      },
      content: [
        {
          columns: [
            {
              image:
                imbB64,
              width: 150,
            },
            [
              {
                text: nameDoc === 'Proforma' ? nameDoc + ' - Válido por 30 días' : nameDoc + '',
                color: '#333333',
                width: '*',
                fontSize: 20,
                bold: true,
                alignment: 'right',
                margin: [0, 0, 0, 15],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'Número de autorización:',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: 'No emitido',
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Fecha de emisión',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 10,
                        alignment: 'right',
                      },
                      {
                        text: dataView.date === undefined ? '' : dataView.date,
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Estado:',
                        color: '#aaaaab',
                        bold: true,
                        fontSize: 10,
                        alignment: 'right',
                        width: '*',
                      },
                      {
                        text: 'No emitido',
                        bold: true,
                        fontSize: 14,
                        alignment: 'right',
                        color: 'red',
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: 'Datos del Emisor',
              color: '#aaaaab',
              bold: true,
              fontSize: 20,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              text: 'Datos del Paciente',
              color: '#aaaaab',
              bold: true,
              fontSize: 20,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              text: profile.firstName === undefined ? 'Razón Social: ' : 'Razón Social: ' + profile.firstName + ' ' + profile.lastName,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: patient.names ? 'Nombres y Apellidos: ' + patient.names + ' ' + patient.lastNames : 'Nombres y Apellidos: ',
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.ide === undefined ? 'Identificación: ' : 'Identificación: ' + profile.ide,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !patient.id ? 'Identificación: ' : 'Identificación: ' + patient.id,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.address === undefined ? 'Dirección: ' : 'Dirección: ' + profile.address,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !patient.street ? 'Dirección: ' : 'Dirección: ' + patient.street + ' y ' + patient.street2,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.phone === undefined ? 'Teléfono: ' : 'Teléfono: ' + profile.phone,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !patient.phone2 ? 'Teléfono: ' : 'Teléfono: ' + patient.phone2,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: profile.email === undefined ? 'Email: ' : 'Email: ' + profile.email,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: !patient.email ? 'Email: ' : 'Email: ' + patient.email,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },

        '\n\n',
        {
          width: '100%',
          alignment: 'center',
          text: 'Descripción',
          bold: true,
          margin: [0, 10, 0, 10],
          fontSize: 15,
        },
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              if (i === 1 || i === 0) {
                return '#bfdde8'
              }
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 2
            },
            paddingBottom: function (i, node) {
              return 2
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '50%', '15%', '10%'],
            body: productTable,
          },
        },
        '\n',
        '\n\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i, node) {
              return 1
            },
            vLineWidth: function (i, node) {
              return 1
            },
            hLineColor: function (i, node) {
              return '#eaeaea'
            },
            vLineColor: function (i, node) {
              return '#eaeaea'
            },
            hLineStyle: function (i, node) {
              // if (i === 0 || i === node.table.body.length) {
              return null
              // }
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i, node) {
              return 10
            },
            paddingRight: function (i, node) {
              return 10
            },
            paddingTop: function (i, node) {
              return 3
            },
            paddingBottom: function (i, node) {
              return 3
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return '#fff'
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Subtotal de pago:',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  border: [false, true, false, true],
                  text: '$' + (parseFloat(subtoTal).toFixed(2)),
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'IVA 12%',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: '$' + '0.00',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'TOTAL DESCUENTO',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: dataView.dsctoType + (dataView.descuento ? parseFloat(dataView.descuento).toFixed(2) : '0.00'),
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Monto Total',
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                },
                {
                  text: 'USD $' + (parseFloat(totalView).toFixed(2)),
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
        },
        {
          text: '________________________________________',
          style: 'doctorRubric',
          aligment: 'center',
        },
        {
          text: profile.firstName === undefined ? 'Dr. ' : 'Dr. ' + profile.firstName + ' ' + profile.lastName,
          style: 'doctorRubricName',
          aligment: 'center',
        },
        {
          text: profile.ide === undefined ? ' ' : 'C.I. ' + profile.ide,
          style: 'doctorRubricName',
          aligment: 'center',
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 8,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 8,
          margin: [10, 10, 10, 10],
        },
        doctorRubric: {
          fontSize: 12,
          bold: true,
          margin: [0, 80, 0, 0],
        },
        doctorRubricName: {
          fontSize: 12,
          bold: true,
        },
      },
      defaultStyle: {
        columnGap: 20,
        // font: 'Quicksand',
      },
    }
    var docDefinition = pdfFormat
    pdfMake.createPdf(docDefinition).download(dataView.numsecuencial === undefined ? nameDoc + '.pdf' : nameDoc + '-' + dataView.numsecuencial + '.pdf')
    resolve(true)
  })
}
